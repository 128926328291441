import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/lib/jenkins/workspace/jelly-bdc-prod-scheduled/www/bdc/src/templates/default-markdown/index.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`business.com is committed to making our website's content accessible and user friendly to everyone. If you are having difficulty viewing or navigating the content on this website, or notice any content, feature, or functionality that you believe is not fully accessible to people with disabilities please call our Customer Service team at `}<a parentName="p" {...{
        "href": "tel:888-393-5000"
      }}>{`888-393-5000`}</a>{` or email our team at `}<a parentName="p" {...{
        "href": "mailto:connect@business.com"
      }}>{`connect@business.com`}</a>{` with “Disabled Access” in the subject line and provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement. We take your feedback seriously and will consider it as we evaluate ways to accommodate all of our customers and our overall accessibility policies. Additionally, while we do not control such vendors, we strongly encourage vendors of third-party digital content to provide content that is accessible and user friendly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      